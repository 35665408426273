import { lazy, Suspense } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Loader from "./components/shared/Loader/Loader";

// Layouts
const MainLayout = lazy(() => import("./Layout/MainLayout"));
const Homepage = lazy(() => import("./pages/HomePage/Homepage"));
const ServicesPage = lazy(() => import("./pages/Services/ServicesPage"));
const ContactUsPage = lazy(() => import("./pages/ContactPage/ContactUsPage"));
function App() {
  return (
    <div className="app">
      <MainLayout>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<Loader />}>
                <Homepage />
              </Suspense>
            }
          />
          <Route
            path="/services"
            element={
              <Suspense fallback={<Loader />}>
                <ServicesPage />
              </Suspense>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Suspense fallback={<Loader />}>
                <ContactUsPage />
              </Suspense>
            }
          />
        </Routes>
      </MainLayout>
    </div>
  );
}

export default App;
